import React from "react"
import Result from "../components/quiz/result"
import PrivateRoute from "../components/context/privateRoute"
import { Router } from "@reach/router"

const ResultPage = props => {
  return (
    <Router>
      <PrivateRoute
        path="/result"
        component={Result}
        paperId={props?.location?.state?.paperId}
        identifier={props?.location?.state?.identifier}
        quizTime={props?.location?.state?.quizTime}
      />
    </Router>
  )
}
export default ResultPage
